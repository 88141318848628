/**
 * External dependencies
 */
import React, {useState} from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import classNames from "classnames"
/**
 * Internal dependencies
 */
 import { useQuery } from "@apollo/client"
 import { toCapitalize } from "@components/common/utils";
 import gql from "graphql-tag"
import Button from "../button/button"
import LinkIcon from "../link-icon/link-icon"
import "./styles/_index.scss"
import Modal from 'react-bootstrap/Modal';
import BookAViewingForm from "../../Forms/BookAViewing"
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
import {
  OFFICE_PAGE_URL
} from "@components/common/site/constants"
const PropertyCallout = (props) => {
  const [showForm, setShowForm] = useState(false);
  const openform = () => {
          setShowForm(!showForm)
  }
  
  const shareurl = typeof window !== 'undefined' ? window.location.href : ''

  const GET_OFFICE_DETAIL = gql`
  query GetOfficeDetail($URL: String!) {
    offices(where: { Office_Crm_Id: $URL }) {
      id
      imagetransforms
      Office_Name
      Tail_Image {
        url
        alternativeText
      }
      Office_Address
      Office_Email
      URL
      Office_Telephone_Sales
      Office_Telephone_Lettings
      Office_Telephone_Progression
     
    }
  }
`
  const { loading, error, data } = useQuery(GET_OFFICE_DETAIL, {
    variables: { URL: props?.data?.office_crm_id },
  })

  let processedImages = JSON.stringify({});
  if (data?.offices?.length > 0 && data?.offices[0]?.imagetransforms?.Tail_Image_Transforms) {
    processedImages = data?.offices[0]?.imagetransforms?.Tail_Image_Transforms;
  }


 return(
  <div className="property-callout pt-32 pb-lg-32 pb-0">
       {(props.data.status!=="Sold Subject To Contract" || data?.offices[0])&&
    <div className="property-callout__head d-flex flex-column">
    <h4 className="d-none d-lg-block text-center">interested?</h4>
      <div className={
        !data?.offices[0]?.Office_Telephone_Sales || props?.data?.search_type === "lettings" ? "no-bot-btn property-callout__actions d-none d-lg-block"
        :
        "property-callout__actions d-none d-lg-block"}>
          {props.data.status!=="Sold Subject To Contract"&&
        <Button displayAsLink={false} color="yellow" onClick={openform}>
          <Button.Icon>
            <i className="icon icon--mail"></i>
          </Button.Icon>

          <Button.Text>Book a Viewing</Button.Text>
        </Button>
        }
        {
          props?.data?.search_type === "lettings" ? ""
          :
          <>
          {data?.offices[0]?.Office_Telephone_Sales &&
          <Button tel={data?.offices[0]?.Office_Telephone_Sales}>
            <Button.Icon>
              <i className="icon icon--phone"></i>
            </Button.Icon>

            <Button.Text>{data?.offices[0]?.Office_Telephone_Sales}</Button.Text>
          </Button>
          }
          </>
        }
      </div>

      {
        props?.data?.search_type === "lettings" ? ""
        :
        <>
        {data?.offices?.length > 0 && data?.offices[0] &&
        <Row className="property-callout__branch g-0">
          {data?.offices[0]?.Tail_Image?.url &&
            <Col xs="auto" className="property-callout__image-wrapper">
              {/* {data.image && (
                <GatsbyImage image={data.image} alt="property callout" />
              )} */}
              <ImageTransform
                    imagesources={data?.offices[0]?.Tail_Image?.url}
                    // renderer="srcSet"
                    imagename="office.Tail_Image.plist"
                    attr={{ alt: `${data?.offices[0]?.Office_Name} - Strakers Estate Agents`, className: "" }}
                    imagetransformresult={processedImages}
                    id={data?.offices[0]?.id}
                  />
            </Col>
          }
          {data?.offices?.length > 0 && data?.offices[0] &&
          <Col className="property-callout__content">
            <h5>{toCapitalize(data?.offices[0]?.Office_Name)}</h5>
            <p>{data?.offices[0]?.Office_Address}</p>

            {/* <LinkIcon text="Contact Branch" order="1" /> */}
            <Link class="link-icon blue-link-hover d-flex align-items-center"
              to={OFFICE_PAGE_URL.alias+'/'+data?.offices[0]?.URL}
            >
              <div className={`link-icon__text fw-bolder fz14 position-relative order-1`}>Contact Branch</div>
              <i className={classNames(`icon icon--arrow-up-right`)}></i>
            </Link>
          </Col>
          }
        </Row>
}
</>
      }
      
    </div>
  }

    <div className="property-callout__booking">
      <h3>valuation</h3>
      <p>
        Do you have a property you&apos;d like to Sell or Let with
        Strakers?
      </p>

      <Button href={"property-valuation-in-wiltshire"} color="outline">
        <Button.Text>Book a Valuation</Button.Text>
      </Button>
    </div>
    <Modal className="teamdetails popupForm" backdrop="static" size="lg" show={showForm} onHide={() => { setShowForm(false) }}>
                                            <Modal.Header closeButton>
                                                <Modal.Title> <div className="form__head">
          <h4>Book A Viewing</h4>
        </div>
</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body> 
                                                <BookAViewingForm
                                                to_admin={data?.offices[0]?.Office_Email?.toLowerCase()}
                                                id={props.data?.id}
                                                 property_img={props.data?.images[0]?.url}
                                                  page_url={shareurl}
                                                   property_title={props.data?.display_address}
                                                    type={props.data?.search_type}
                                                data={props?.data}/>  
                                            </Modal.Body>

                                        </Modal>
  </div>
)
          }
export default PropertyCallout
