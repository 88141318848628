/**
 * External dependencies
 */
import React, { useState, useRef, useEffect } from 'react'
import classNames from 'classnames';
/**
 * Internal dependencies
 */
import { pickBy, identity, isArray, get, isEmpty, isString, find } from "lodash"
import './styles/_index.scss';
import ContentBlock from "../content/Content";
import { Container, Row, Col } from "react-bootstrap"
const HiddenText = ({
  title,
  text,
  lotData,
  department
}) => {
  const [isOpen, setIsOpen] = useState(text && text.length < 300 ? true : false);
  const parent = useRef('');


  useEffect(() => {
    if (text && text.length > 300) {
      parent.current.classList.add('has-overlay')
    }
  }, []);

  const onToggleReadMore = () => {
    if (isOpen) {
      setTimeout(() => {
        parent.current.classList.add('has-overlay')
      }, 400)
    } else {
      parent.current.classList.remove('has-overlay')
    }

    setIsOpen(!isOpen);
  }

  return (
    <div className={classNames('hidden-text pt-32 pb-32 ', { 'is-open': isOpen })} ref={parent}>
      {department!=="auction"&&title && <h5>{title}</h5>}
      <div className="hidden-text__inner position-relative">
        {/* <p>{text}</p> */}
        {/* <ContentBlock Content={text} /> */}
        {department!=="auction"&&
        <div
          dangerouslySetInnerHTML={{ __html: text }}
        />
        }
        {isArray(lotData) && lotData?.length > 0 &&
          <div className='margin-top-15-fix'>
            {lotData?.map((collection, index) => {
             var id = collection?.Id ? collection?.Id : collection?.ID ? collection?.ID : 0 
              return (
                <>
                  {id !== 0 ?
                    <div
                      // className="property-info__feature position-relative"
                      key={index}
                    >
                      <h5>{collection.Name}</h5>

                      {/* <p>{}</p> */}
                      <ContentBlock Content={collection.Value} />
                    </div>
                    : null
                  }</>)
            }
              // : 
              // <ContentBlock Content={props?.data?.accomadation_summary} />
            )}

          </div>
        }
      </div>
      {text && text.length > 300 &&
        <button
          className="clear-btn hidden-text__trigger"
          onClick={onToggleReadMore}
        >

          {isOpen ? 'Show Less' : 'Read More'}
        </button>}
    </div>
  )
}

export default HiddenText