import * as React from "react";

import { StarberryIcons } from "@components/icons/index";

import "./textField.scss";

const TextField = (props) => {

    const { type = 'text', inlineIcon = '', className, inputClassName, label, error = '', ...rest } = props;

    return(
        <div className={`text-field-wrapper form-wrap ${className || ''}`}>
            {
                label && (
                    <label  className='form-label'>
                        { label }
                    </label>
                )
            }
            <div
                className={`text-field ${inlineIcon ? 'has-inline-icon' : ''} ${inputClassName || ''} ${error || ''}`}
            >            
                {/* {
                    inlineIcon && (
                        <StarberryIcons
                            iconName={inlineIcon}
                            className="inline-icon"
                        />
                    )
                } */}
                <input
                className="form-control"
                    type={type}
                    {...rest}
                />
            </div>
        </div>
    )
}

export default TextField
