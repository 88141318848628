import React, { useState } from "react"
import { Button, Col, Row } from "react-bootstrap";
import { numberFormat } from "./util"
import { currencyFormat, filterNumber } from "@components/common/utils";
import TextField from "@components/formComponents/textField";
import ReactSelect from "@components/formComponents/reactSelect";
import {
  calculate,
  countries
} from "@starberry/react-calculator-stampduty"


const buyerTypes = [
  {
    value: "first",
    label: "First Time Buyer",
  },
  {
    value: "home",
    label: "Home Mover (main residence and not a first time buyer)",
  },
  {
    value: "investor",
    label: "Second Homes / BTL",
  },
]
// DO NOT DO ANY CHNAGES - START
const StampdutyCalc = props => {

  const currency = process.env.GATSBY_SITE_CURRENCY ? process.env.GATSBY_SITE_CURRENCY : "£";

  const [result, setResult] = useState(null)
  const [buyerSelected, setBuyerSelected] = useState({ value: 'first', label: 'First Time Buyer' });
  const [propertyValue, setPropertyValue] = useState(numberFormat(filterNumber(props.price)))

  const [buyer, setBuyer] = useState(props.buyerType)

  const formatPrice = str => {
    return currency + str.toLocaleString("en-US")
  }

  const doCalculate = () => {
    const results = calculate(
      filterNumber(propertyValue),
      'residential',
      countries.ENGLAND,
      buyer
    )
    let effectiveRate = (results.tax / filterNumber(propertyValue)) * 100
    effectiveRate = new Intl.NumberFormat("en-US", {}).format(effectiveRate)

    if (effectiveRate === "NaN") {
      effectiveRate = 0
    }

    let summaryBands = []
    results.summaryBands.map(result => {
      return summaryBands.push(
        result.adjustedRate +
        "% between " +
        formatPrice(result.start) +
        " and " +
        formatPrice(result.end)
      )
    })
    const result = {
      effectiveRate: effectiveRate + "%",
      summaryBands: summaryBands,
      tax: formatPrice(results.tax),
    }
    setResult(result)
  }

  const handleSubmit = event => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Calculator',
      'formType': props.title,
      'formId': "Stamp Duty Calculator",
      'formName': "Stamp Duty Calculator",
      'formLabel': "Stamp Duty Calculator"
    });
    event.preventDefault()
    event.stopPropagation()
    doCalculate();
  }

  const handleDeposit = event => {
    setPropertyValue(numberFormat(filterNumber(event.target.value)))
  }

  return (
    <div className="stamp-duty-wrapper calculator-form">
      <Row>
        <Col md="6" className="flex-shrink-1 ps-md-2  mb-5 col-md-6 col-12" >
          <TextField
            name="PropertyValue"
            type="text"
            label="What is the purchase price?"
            class="form-control"
            inlineIcon="poundIcon"
            inputClassName="outline-grey-border"
            placeholder={'Purchase Price'}
            value={propertyValue}
            onChange={(e) => {
              setResult(null)
              handleDeposit(e)
            }}
          />
        </Col>
        <Col md="6" className="flex-shrink-1 ps-md-2  mb-5 col-md-6 col-12" >
          <div className="text-field-wrapper stmp-fi">
            <label className="form-label"> I am... </label>
            <ReactSelect
              value={buyerSelected} 
              onChange={(selected) => {
                setResult(null)
                setBuyerSelected(selected);
                setBuyer(selected.value)
              }}
              options={buyerTypes}
              components={{
                IndicatorSeparator: () => null
              }} 
            />
          </div>
        </Col>
        </Row>
        <Row>
        <Col md="12" >
          {result && (
            <div className="monthly-payment investment-monthly-payment pb-5">
              <p className="text-left mb-0 bold-para">Stamp Duty</p>
              <h3 className="text-left py-5">{result.tax}</h3>
              <p className="text-left mb-0">You have to pay stamp duty:</p>
              {result.summaryBands.map((sm, i) => {
                return <p className="text-left mb-0">{sm}</p>
              })}
              <p className="text-left mb-0">
                Your effective stamp duty rate is {result.effectiveRate}
              </p>
            </div>
          )}
        </Col>
      </Row>
        <Row>
        <Col md="6" className="form-action flex-shrink-1 ps-md-2 col-md-6 col-12" >
          <Button
            variant="primary-light"
            onClick={(event) => { handleSubmit(event) }}
            className="btn is-larger btn--yellow body-l"
          >
            Calculate
          </Button>
        </Col>
        </Row>
      
      

    </div>
  )
} 

export default StampdutyCalc
