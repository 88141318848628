import React from 'react';
import Select from 'react-select';

import "./reactSelect.scss";

const defaultStyles =  {
    control: styles => ({
        ...styles,
        color: '#4A5568',
        border: "1px solid #E2E8F0",
        minHeight: '50px',
        paddingLeft: '15px',
        marginBottom: '1rem',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
        borderRadius: '4px'
    }),
    placeholder: (defaultStyles) => {
        return {
        ...defaultStyles,
        color: '#4A5568',
        }
    },
    singleValue: styles => ({ ...styles })
}

const ReactSelect = (props) => {

    const { label, styles, wrapperClassName, ...rest } = props;

    const customStyles = {
        ...defaultStyles,
        ...styles
    }

    return (
        <div className={`react-select-wrapper ${wrapperClassName || ''}`}>
            {
                label && (
                    <label className='form-label'>
                        {label}
                    </label>
                )
            }
            <Select
                {...rest}
                styles={customStyles}
            />
        </div>
    )    
}

export default ReactSelect;