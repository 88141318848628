/**
 * External dependencies
 */
import React from "react"
import { Row, Col } from "react-bootstrap"

/**
 * Internal dependencies
 */
import "./styles/_index.scss"
import { currencyFormat } from '@components/common/utils';
import PriceComp from "@components/Property/PriceDetail"
import moment from "moment";
const PropertyIntro = ({ data = {} }) => (
  <div className="property-intro pt-24 pb-32">
    <Row className="g-0 align-items-center">
      {data.department==="auction"&&
      <>
     {data?.extra?.lotNumber &&<h3 className="auction-lot-number">Lot {data?.extra?.lotNumber}</h3>}
     {data?.extra?.onlineAuction?.EndDate &&<h5 className="auction-date-details">{data?.extra?.onlineAuction?.EndDate ? moment(data?.extra?.onlineAuction?.EndDate).format("DD MMMM YYYY") : ""}</h5>}
     </>
}
    <PriceComp {...data} />

    </Row>
    <div className="property-intro__description">
      <h1 className="property-disp-address">
        {data?.display_address}
      </h1>
      {data.department!=="auction"&&
      <p>{data?.title}</p>
      }
      {
        data.department==="auction"&&data.extra.lotData.length>0&&
        data.extra.lotData.map(data=>{
          if(data.Name==="Website Tagline"){
            return(
            <p>{data.Value}</p>
            )
          }
        })

      }
    </div>
  </div>
)

export default PropertyIntro
