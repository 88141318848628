/**
 * External dependencies
 */
import { useStaticQuery, graphql } from "gatsby"

const usePropertyInfo = () => {
  const {
    allFile: { nodes: items },
  } = useStaticQuery(graphql`
     query PropertyInfo {
       allFile(
         filter: { relativeDirectory: { eq: "property-info/images" } }
       ) {
         nodes {
           relativePath
           childImageSharp {
             gatsbyImageData(layout: FULL_WIDTH)
           }
         }
       }
     }
   `)

  return items
}

export default usePropertyInfo
