/**
 * External dependencies
 */
import React, {useState} from "react"
import { Row, Col, Tabs, Tab } from "react-bootstrap"
import { Link } from "gatsby"

/**
 * Internal dependencies
 */
import CalculatorForm from "../../blocks/calculator-form/calculator-form"
import "./styles/_index.scss"
import StampdutyCalc from "@components/calculators/stampdutycalc/property"
import RentalyieldCalc from "@components/calculators/rentalyieldcalc/property"
import MortgageCalc from "@components/calculators/mortgagecalc"
import "./../../blocks/calculator-form/styles/_index.scss"
import Modal from 'react-bootstrap/Modal';
import SpeakMotrgageForm from "../../Forms/SpeakMotrgage"
const PropertyCalculator = (props) => {
  const [showForm, setShowForm] = useState(false);
  const openform = () => {
          setShowForm(!showForm)
  }
  const shareurl = typeof window !== 'undefined' ? window.location.href : ''
  return(

  <div className="property-calculator pt-32">
    {/* {props?.search_type} */}
    <Tabs defaultActiveKey={props?.search_type === "sales" ? "1" : "3"}>
      {props?.search_type === "sales" &&
      <Tab eventKey="1" title="Mortgage Calculator">
        {/* <CalculatorForm /> */}
        <MortgageCalc propertyPrice={props.price} />
      </Tab>
      }
      {props?.search_type === "sales" &&
      <Tab eventKey="2" title="Stamp Duty Calculator">
        {/* <CalculatorForm /> */}
        <StampdutyCalc title={"Stamp Duty"} className={`mt-16 `} desc={""} price={props.price} />
      </Tab>
      }
      {props?.search_type === "sales" &&
      <Tab eventKey="3" title="Rental Yield Calculator">
        {/* <CalculatorForm /> */}
        <RentalyieldCalc title={""} className={`mt-16 `} desc={""} price={props?.price}/>
      </Tab>
      }
    </Tabs>
    {props?.search_type === "sales" &&
    <Row className="property-calculator__callout g-0 align-items-center">
      <Col xs="auto">
      <a onClick={openform} href="javascript:void(0)"><i className="icon icon--user" /></a>
      </Col>

      <Col>
        <a className="text-bt" onClick={openform} href="javascript:void(0)">Contact our recommended Mortgage Broker</a>
      </Col>
    </Row>
    }
        <Modal className="teamdetails popupForm" backdrop="static" size="lg" show={showForm} onHide={() => { setShowForm(false) }}>
                                            <Modal.Header closeButton>
                                                <Modal.Title> <div className="form__head">
          <h4>
          Contact our recommended Mortgage Broker</h4>
        </div>
</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body> 
                                                <SpeakMotrgageForm
                                                office={props?.office}
                                                id={props.data?.id}
                                                 property_img={props.data?.images[0]?.url}
                                                  page_url={shareurl}
                                                   property_title={props.data?.display_address}
                                                    type={props.data?.search_type}
                                                data={props?.data}/>  
                                            </Modal.Body>

                                        </Modal>
  </div>
)
  }

export default PropertyCalculator
