 
/**
 * Retal yeild calculation
 */
export const rentalYield = (rate, rent) => {
  if (isNaN(rate) || isNaN(rent) || !rent || !rate) {
    return 0
  }

  if (rate === 0 || rent === 0) {
    return 0
  } else {
    return ((rent * 12) / rate) * 100
  }
}
