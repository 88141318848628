/**
 * External dependencies
 */
 import React, {useState} from "react"
import { Container, Row, Col } from "react-bootstrap"
import classNames from "classnames"
/**
 * Internal dependencies
 */
import LinkIcon from "../link-icon/link-icon"
import "./styles/_index.scss"
import ContentBlock from "../content/Content";
import Modal from 'react-bootstrap/Modal';
import SpeakMotrgageForm from "../../Forms/SpeakMotrgage"
import Removals from "../../Forms/Removals"
const CalloutBanner = ({
  title,
  subTitle,
  description,
  linkText,
  linkHref,
  addShell = true,
  Link_Type,
  isForm,
  data,
  search_type,
  price,
  office,
  to_admin
}) => {
  const Content = (props) => {
    const [showForm, setShowForm] = useState(false);
    const openform = () => {
            setShowForm(!showForm)
    }
    const shareurl = typeof window !== 'undefined' ? window.location.href : ''
    return(
    <Row className="callout-banner__inner justify-content-lg-between bg-blue g-0 position-relative">
      <Col className="callout-banner__col position-relative">
        {subTitle && <h5>{subTitle}</h5>}

        <Row className="g-0 align-items-baseline">
          <Col xs={12} lg="auto">
            {/* {description && <p>{description}</p>} */}
            <ContentBlock Content={description} />
          </Col>

          <Col xs="auto">
            {isForm ?
          <a onClick={openform}
                            class="link-icon blue-link-hover d-flex align-items-center"
                            
                          >
                            
                             <div
      className={`link-icon__text fw-bolder fz14 position-relative order-1`}
    >
      Click to find out more
    </div>
    <i className={classNames(`icon icon--arrow-up-right`)}></i>
                          </a> :
                          <>
            {linkText && <LinkIcon 
            
            href={linkHref?.Alias} text={linkText}
            
             />}
             </>}
          </Col>
        </Row>
      </Col>
      <Col
        lg="auto"
        className="callout-banner__col d-none d-lg-block position-relative"
      >
        {title && <h3 className="removal-text">{title}</h3>}
      </Col>
      <Modal className="teamdetails popupForm" backdrop="static" size="lg" show={showForm} onHide={() => { setShowForm(false) }}>
                                            <Modal.Header closeButton>
                                                <Modal.Title> <div className="form__head">
          <h4>
          Speak to Removal Company</h4>
        </div>
</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body> 
                                                <Removals
                                                office={office}
                                                id={data?.id}
                                                to_admin={to_admin}
                                                 property_img={data?.images[0]?.url}
                                                  page_url={shareurl}
                                                   property_title={data?.display_address}
                                                    type={data?.search_type}
                                                data={data}/>  
                                            </Modal.Body>

                                        </Modal>
    </Row>
  )
}

  return (
    <div className="callout-banner">
      {addShell
        ? <Container fluid="hg">
          <Content />
        </Container>
        : <Content />
      }
    </div>
  )
}

export default CalloutBanner
