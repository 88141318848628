/**
 * External dependencies
 */
import React, { useState, useCallback } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import { toCapitalize } from "@components/common/utils";
/**
 * Internal dependencies
 */
import IconText from '../icon-text/icon-text';
import Modal from '../modal/modal';
import './styles/_index.scss';
import Slider from "react-slick";
import Lightbox from 'react-image-lightbox';
import ImageTransform from "../../components/common/ggfx-client/module/components/image-transform";
import 'react-image-lightbox/style.css';
const PropertySchema = ({ data = {} }) => {
  const [modalShow, setModalShow] = useState(false);

  const closeModal = useCallback(() => setModalShow(false), []);

  // const isLastOrPenultimate = (index) => index === data.bullets.length - 2 || index === data.bullets.length - 1;
  const settings = {
    dots: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1402,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 377,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  const floorplanImages = data?.floorplan
  // Floorplan slider


  // Property floorplan images lightbox
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openPropertyImage = (e, ind) => {
      e.preventDefault();
      setPhotoIndex(ind);
      setIsOpen(true);
  }
  var propertyLightImagesFloorplan = floorplanImages && floorplanImages.map(img => img.url);
  // Property floorplan images lightbox


  return ((data?.bedroom) || (data?.bathroom) || (data?.reception) || (data?.building) || (data?.floorplan?.length > 0) ?
    <div className="property-schema pt-32 pb-32">
      <Row className="g-0 flex-column flex-lg-row">
        <Col>
          <Row className="property-schema__bullets g-0 justify-content-start justify-content-md-start justify-content-lg-between">
            {data?.bedroom ?
              <Col
                xs="auto"
                md={6}
                key={"bed"}
                className={classNames('property-schema__bullet'
                // , {
                  // 'd-none d-md-block': isLastOrPenultimate(0)
                // }
                )
              }
              >
                <IconText
                  icon={"bed"}
                  title={"Bedrooms:"}
                  description={data?.bedroom}
                />
              </Col>
         : null}
         {data?.bathroom ?
              <Col
                xs="auto"
                md={6}
                key={"bath"}
                className={classNames('property-schema__bullet'
                // , {
                  // 'd-none d-md-block': isLastOrPenultimate(0)
                // }
                )
              }
              >
                <IconText
                  icon={"bath"}
                  title={"Bathrooms:"}
                  description={data?.bathroom}
                />
              </Col>
         : null}
         {data?.reception ?
              <Col
                xs="auto"
                md={6}
                key={"chair"}
                className={classNames('property-schema__bullet'
                )
              }
              >
                <IconText
                  icon={"chair"}
                  title={"Reception Rooms:"}
                  description={data?.reception}
                />
              </Col>
        : null}
        {data?.building ?
              <Col
                xs="auto"
                md={6}
                key={"house"}
                className={classNames('property-schema__bullet  d-none d-md-block'
                // , {
                  // 'd-none d-md-block': isLastOrPenultimate(0)
                // }
                )
              }
              >
                <IconText
                  icon={"house"}
                  title={"Property Type:"}
                  description={toCapitalize(data?.building)}
                />
              </Col>
         : null}
          </Row>
        </Col>
        {/* {JSON.stringify(data?.floorplan)} */}
        {
                                                  data?.floorplan?.length > 0 && 
        <Col className="property-schema__illustration">
          <Row className="g-0">
            <Col xs="auto" className="property-schema__zoom align-self-end">
              <button
                className="clear-btn d-flex justify-content-center align-items-center"
                onClick={(e) => openPropertyImage(e, 0)}
              >
                <i className="icon icon--zoom" />
              </button>
            </Col>

            <Col className="property-schema__image-wrapper">
              {/* <GatsbyImage image={data?.schema} alt="property schema" /> */}
              <Slider {...settings}>
                                              {
                                                  floorplanImages && floorplanImages.map((node, i) => {
                                                  let processedImages = JSON.stringify({});
                                                  if (data?.imagetransforms?.images_Transforms) {
                                                      processedImages = data?.imagetransforms.images_Transforms;
                                                  }

                                                      return (
                                                          <div className="" key={node?.url}>
                                                              <ImageTransform
                                                                  imagesources={node?.url ? node?.url : node?.srcUrl ? node?.srcUrl : ""}
                                                                  renderer="srcSet"
                                                                  imagename="property.images.floorplan"
                                                                  attr={{ alt: 'Property', class:'' }}
                                                                  imagetransformresult={processedImages}
                                                                  id={data?.id}
                                                              />
                                                              <a href="javascript:void(0)" onClick={(e) => openPropertyImage(e, 0)}>
                                                                  <i className="icon-zoom">
                                                                  </i>
                                                              </a>
                                                          </div>
                                                      )
                                                  })
                                              }
                                          </Slider>
            </Col>
        
          </Row>
        </Col>
}
      </Row >
 {/* Property floorplan image lightbox */}
 {isOpen && (
                <Lightbox
                    mainSrc={propertyLightImagesFloorplan[photoIndex]}
                        nextSrc={floorplanImages.length > 1 ? propertyLightImagesFloorplan[(photoIndex + 1) %propertyLightImagesFloorplan.length] : undefined}
                        prevSrc={floorplanImages.length > 1 ? propertyLightImagesFloorplan[(photoIndex + propertyLightImagesFloorplan.length - 1) % propertyLightImagesFloorplan.length] :undefined}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + propertyLightImagesFloorplan.length - 1) %propertyLightImagesFloorplan.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % propertyLightImagesFloorplan.length)
                    }
                />
            )}
            {/* Property floorplan image lightbox */}
      {/* <Modal
        modalShow={modalShow}
        modalContent={(<Modal.Image image={data?.schema} />)}
        closeModal={closeModal}
      /> */}
    </div >
   : null)
}

export default PropertySchema