/**
 * External dependencies.
 */
import React from "react"
import { Col, Form, Row } from "react-bootstrap"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import CurrencyInput from "react-currency-input-field"

/**
 * Internal dependencies.
 */
import FieldSelect from "../../components/field-select/field-select"
import "./styles/_index.scss"

const schema = yup.object({
  rental: yup.string().required("Required"),
  "property-price": yup.string().required("Required"),
  "stamp-duty": yup.string().required("Required"),
  "valuation-fees": yup.string().required("Required"),
  "survey-fees": yup.string().required("Required"),
  "legal-fees": yup.string().required("Required"),
  deposit: yup.string().required("Required"),
  "mortage-fees": yup.string().required("Required"),
  costs: yup.string().required("Required"),
  "buying-costs": yup.string().required("Required"),
  rent: yup.string().required("Required"),
  "annual-rent": yup.string().required("Required"),
})

const CalculatorForm = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })
  const onSubmit = data => console.log(data)

  const handleError = name =>
    errors[name] && (
      <div className="error mt-2">
        <small className="text-danger">{errors[name].message}</small>
      </div>
    )
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="calculator-form">
      <Row className="calculator-form__row-1">
        <Col xs="12" className="flex-shrink-1 ps-md-2  mb-5">
          <Controller
            render={({ field }) => (
              <Form.Group>
                <FieldSelect
                  field={field}
                  fontSize="1.6rem"
                  borderRadius="4px"
                  options={[
                    {
                      value: "Rental Yeild Based on Property Purchase Cost",
                      label: "Rental Yeild Based on Property Purchase Cost",
                    },
                    {
                      value: "Rental Yeild Based on Property Purchase Cost",
                      label: "Rental Yeild Based on Property Purchase Cost",
                    },
                  ]}
                  placeholder="Rental Yeild Based on Property Purchase Cost"
                  styles={{
                    control: (styles, state) => ({
                      ...styles,
                      border: '1px solid #dcddde',
                      height: 'initial',
                      outline: 'none',
                      borderColor: state.menuIsOpen && "#dcddde",
                      boxShadow: state.menuIsOpen && "none",
                      "&:hover": {
                        borderColor: state.menuIsOpen && "#dcddde",
                      },
                    }),
                    valueContainer: styles => ({
                      ...styles,
                      padding: '15px 8px'
                    }),
                    placeholder: styles => ({
                      ...styles,
                      lineHeight: '1.3'
                    })
                  }}
                />
              </Form.Group>
            )}
            control={control}
            name="rental"
          />
          {handleError("rental")}
        </Col>
        <Col xs="12" md="6" className="flex-shrink-1 ps-md-2  mb-5">
          <Controller
            render={({ field }) => (
              <Form.Group>
                <Form.Label>Property Purchase Price</Form.Label>
                <CurrencyInput
                  className="form-control"
                  prefix="£ "
                  placeholder="£ 430,000"
                  name={field.name}
                  decimalsLimit={2}
                  onValueChange={value => field.onChange(value)}
                />
              </Form.Group>
            )}
            control={control}
            name="property-price"
          />
          {handleError("property-price")}
        </Col>
        <Col xs="12" md="6" className="flex-shrink-1 ps-md-2  mb-5">
          <Controller
            render={({ field }) => (
              <Form.Group>
                <Form.Label>Stamp duty</Form.Label>
                <CurrencyInput
                  className="form-control"
                  placeholder="£ 3,000"
                  prefix="£ "
                  name={field.name}
                  decimalsLimit={2}
                  onValueChange={value => field.onChange(value)}
                />
              </Form.Group>
            )}
            control={control}
            name="stamp-duty"
          />
          {handleError("stamp-duty")}
        </Col>
        <Col xs="12" md="6" className="flex-shrink-1 ps-md-2  mb-5">
          <Controller
            render={({ field }) => (
              <Form.Group>
                <Form.Label>Valuation fees</Form.Label>
                <CurrencyInput
                  className="form-control"
                  placeholder="£ 4,000"
                  prefix="£ "
                  name={field.name}
                  decimalsLimit={2}
                  onValueChange={value => field.onChange(value)}
                />
              </Form.Group>
            )}
            control={control}
            name="valuation-fees"
          />
          {handleError("valuation-fees")}
        </Col>
        <Col xs="12" md="6" className="flex-shrink-1 ps-md-2  mb-5">
          <Controller
            render={({ field }) => (
              <Form.Group>
                <Form.Label>Survey fees</Form.Label>
                <CurrencyInput
                  className="form-control"
                  prefix="£ "
                  placeholder="£ 2,000"
                  name={field.name}
                  decimalsLimit={2}
                  onValueChange={value => field.onChange(value)}
                />
              </Form.Group>
            )}
            control={control}
            name="survey-fees"
          />
          {handleError("survey-fees")}
        </Col>
        <Col xs="12" md="6" className="flex-shrink-1 ps-md-2  mb-5">
          <Controller
            render={({ field }) => (
              <Form.Group>
                <Form.Label>Legal fees</Form.Label>
                <CurrencyInput
                  className="form-control"
                  prefix="£ "
                  placeholder="£ 3,000"
                  name={field.name}
                  decimalsLimit={2}
                  onValueChange={value => field.onChange(value)}
                />
              </Form.Group>
            )}
            control={control}
            name="legal-fees"
          />
          {handleError("legal-fees")}
        </Col>
        <Col xs="12" md="6" className="flex-shrink-1 ps-md-2  mb-5">
          <Controller
            render={({ field }) => (
              <Form.Group>
                <Form.Label>Mortgage deposit</Form.Label>
                <CurrencyInput
                  className="form-control"
                  prefix="£ "
                  placeholder="£ 1,500"
                  name={field.name}
                  decimalsLimit={2}
                  onValueChange={value => field.onChange(value)}
                />
              </Form.Group>
            )}
            control={control}
            name="deposit"
          />
          {handleError("deposit")}
        </Col>
        <Col xs="12" md="6" className="flex-shrink-1 ps-md-2  mb-5">
          <Controller
            render={({ field }) => (
              <Form.Group>
                <Form.Label>Mortgage fees</Form.Label>
                <CurrencyInput
                  className="form-control"
                  prefix="£ "
                  placeholder="£ 4,000"
                  name={field.name}
                  decimalsLimit={2}
                  onValueChange={value => field.onChange(value)}
                />
              </Form.Group>
            )}
            control={control}
            name="mortage-fees"
          />
          {handleError("mortage-fees")}
        </Col>
        <Col xs="12" md="6" className="flex-shrink-1 ps-md-2  mb-5">
          <Controller
            render={({ field }) => (
              <Form.Group>
                <Form.Label>Refurbishment costs</Form.Label>
                <CurrencyInput
                  className="form-control"
                  prefix="£ "
                  placeholder="£ 3,000"
                  name={field.name}
                  decimalsLimit={2}
                  onValueChange={value => field.onChange(value)}
                />
              </Form.Group>
            )}
            control={control}
            name="costs"
          />
          {handleError("costs")}
        </Col>
      </Row>
      <Row className="calculator-form__row-2">
        <Col xs="12" md="6" className="flex-shrink-1 ps-md-2  mb-5">
          <Controller
            render={({ field }) => (
              <Form.Group>
                <Form.Label>Other buying costs</Form.Label>
                <CurrencyInput
                  className="form-control"
                  prefix="£ "
                  placeholder="£ 13,000"
                  name={field.name}
                  decimalsLimit={2}
                  onValueChange={value => field.onChange(value)}
                />
              </Form.Group>
            )}
            control={control}
            name="buying-costs"
          />
          {handleError("buying-costs")}
          <p className="mt-1_6">
            <strong>(- less)</strong>
          </p>
        </Col>
      </Row>
      <Row className="calculator-form__row-3">
        <Col xs="12" md="6" className="flex-shrink-1 ps-md-2  mb-5">
          <Controller
            render={({ field }) => (
              <Form.Group>
                <Form.Label>Monthly rent</Form.Label>
                <CurrencyInput
                  className="form-control"
                  prefix="£ "
                  placeholder="£ 13,000"
                  name={field.name}
                  decimalsLimit={2}
                  onValueChange={value => field.onChange(value)}
                />
              </Form.Group>
            )}
            control={control}
            name="rent"
          />
          {handleError("rent")}
        </Col>
      </Row>
      <Row className="calculator-form__row-4">
        <Col xs="12" md="6" className="flex-shrink-1 ps-md-2">
          <Controller
            render={({ field }) => (
              <Form.Group>
                <Form.Label>Annual costs</Form.Label>
                <CurrencyInput
                  className="form-control"
                  prefix="£ "
                  placeholder="£ 13,000"
                  name={field.name}
                  decimalsLimit={2}
                  onValueChange={value => field.onChange(value)}
                />
              </Form.Group>
            )}
            control={control}
            name="annual-rent"
          />
          {handleError("annual-rent")}
          <p className="mt-1_6">
            <strong>(+ More)</strong>
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="6" className="form-action flex-shrink-1 ps-md-2">
          <button type="submit" className="btn is-larger btn--yellow body-l">
            <strong>Calculate</strong>
          </button>
        </Col>
      </Row>
    </form>
  )
}

export default CalculatorForm
