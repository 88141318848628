import React from 'react';
import { Row, Col } from "react-bootstrap"
import { currencyFormat } from '@components/common/utils';
const PriceDetail = (item) => {

    let className = item?.className || 'fw-bolder card__price fz14';
    let prefix = (item?.priceQualifier === "Default" ? "" : item?.priceQualifier) || (item?.price_qualifier === "Default" ? "" : item?.price_qualifier);
    let suffix = '';
    let price = (item?.priceDetails || item?.price || '').toLocaleString()
    if (item.status === 'Let' || item.status === 'To Let') {
        prefix = '';
        suffix = (item?.priceQualifier === "Default" ? "" : item?.priceQualifier) || (item?.price_qualifier === "Default" ? "" : item?.price_qualifier);
    }


    return (
        <>
                   
              {price &&<Col xs="auto" className="property-intro__price">
                {item.department==="auction"?
                <div className='auction-details-price'> 
                  <span >*GUIDE PRICE </span><h3>{currencyFormat(price, '£', false)} + </h3> <span>PLUS FEES</span>
                </div>
        
                :
        <h3>{currencyFormat(price, '£', false)}</h3>

              }
      </Col>}
      {prefix && 
        <Col className="property-intro__offer-text">
        <span className="d-inline-block">{prefix}</span>
      </Col>}
      {suffix && 
      <Col className="property-intro__offer-text">
        <span className="d-inline-block">{suffix}</span>
      </Col>}         

        </>
    )
}

export default PriceDetail;
