/**
 * External dependencies
 */
import React from "react"
import { Row, Col } from "react-bootstrap"

/**
 * Internal dependencies
 */
import "./styles/_index.scss"

const IconText = ({ icon, title, description }) => (
  <Row className="icon-text g-0 align-items-center">
    <Col
      xs="auto"
      className="icon-text__icon d-flex justify-content-center align-items-center"
    >
      <i className={`icon icon--${icon}`} />
    </Col>

    <Col className="icon-text__entry">
      {title && <h6 className="d-none d-md-block">{title}</h6>}
      {description && <p>{description}</p>}
    </Col>
  </Row>
)

export default IconText
