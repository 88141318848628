import React, { useState, useRef, createRef} from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
// import ContentBlock from "../Content/content";
import TextField from "./Inputs/TextField";
import TextArea from "./Inputs/TextArea";
import Recaptcha from "./Inputs/Recaptcha";
import { DatePicker } from 'react-nice-dates'
import TimePicker from 'rc-time-picker';
import 'react-nice-dates/build/style.css'
import 'rc-time-picker/assets/index.css';
import { postFormData } from "./api/Api";
import { ScrollToTop, SendMail, FormValidation, gtmEvent } from "../components/common/utils";
import _ from "lodash";
import moment from 'moment';
import {Link} from "gatsby";
import "./Form.scss";
const SpeakMotrgageForm = (props) => {
    const myRef = createRef(null);
    const recaptchaRef = useRef();
    const initFormState = {
        formname: "Speak to Motrgage Form",
        fname: '',
        lname: '',
        email: '',
        telephone: '',
        message: ''
    }
    const validationFields = ['fname', 'lname', 'email', 'telephone'];
    const [formState, setFormState] = useState(initFormState);
    const [errors, setErrors] = useState({});
    const [errorFlag, setErrorFlag] = useState(false);
    const [showthankyou, setThankyou] = useState(false);
    const [btnProcess, setBtnProcess] = useState(false);
    const handleChange = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value,
        });
    }
    let filterEmail="";
    if(props?.office === "devizes"){
        filterEmail = "ian@integritypi.co.uk";
    } else if (props?.office === "chippenham"){
        filterEmail = "tracyd@fxmortgages.co.uk";
    } else {
        filterEmail = "strakers@chartwellfunding.co.uk";
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        var validation = FormValidation(formState, validationFields);
        if (validation.status) {
            setErrorFlag(false)
            setErrors(validation.errors)
            const token = await recaptchaRef.current.executeAsync();
            if (token) {
                setBtnProcess(true)
                var postData = _.cloneDeep(formState)
                postData['extra'] = formState;
                recaptchaRef.current.reset();
                postFormData(postData).then(async apiRes => {
                    setThankyou(true)
                    setFormState(initFormState)
                    setBtnProcess(false);
                    // scrollTop();
                    myRef?.current?.scrollIntoView({ behavior: 'smooth'})
                    setTimeout(() => {
                        setThankyou(false)
                    }, 9000)
                    // GTM tracking event 
                    gtmEvent({ formType: 'Contact our recommended Mortgage Broker', formName: 'Contact our recommended Mortgage Broker' })
                    // lets send mail
                    postData['property_id'] = props.id ? props.id : '';
                    postData['property_url'] = props.page_url ? props.page_url : '';
                    postData['crm_id'] = props.crm_id ? props.crm_id : '';
                    postData['price'] = props.price ? props.price : '';
                    postData['type'] = props.type ? props.type : '';
                    postData['property_title'] = props.property_title ? props.property_title : '';
                    postData['property_img'] = props.property_img ? props.property_img : '';
                    postData['property_list'] = props.data ? props.data : '';
                    postData['to_admin'] = filterEmail;
                    // postData['to_bcc'] = "marketing@strakers.co.uk";
                    postData['from_bcc'] = props?.office === "devizes" && "georgina.drew@strakers.co.uk";                    
              
                    postData['email_temp_user'] = props?.office === "devizes" ? 'speakmortgage_devizes_user' : 'speakmortgage_user';
                    postData['email_temp_admin'] = 'speakmortgage_admin';
                    postData['email_subject_user'] = 'Strakers - Mortgage Advice';
                    postData['email_subject_admin'] = 'Strakers - Mortgage Advice';
                    await SendMail(postData);
                });
            }
        } else {
            setErrors(validation.errors)
            setErrorFlag(true)
            // scrollTop();
            myRef?.current?.scrollIntoView({ behavior: 'smooth'})
        }
    }

    const scrollTop = () => {
        ScrollToTop(document.getElementById('form-section'), 100)
    }
    const errClass = (field) => {
        if (errors[field]) {
            return 'field-error';
        }
        return ''
    }
    return (
        <React.Fragment>
            <section className='form-section' id="form-section" ref={myRef}>
            <div className="form form--contact bg-white">
    <div className="form__body">
          <div className="form-row">
            {/* {JSON.stringify(props.property_title)} */}
                    <Row>
                            <Col md={12}>
                                        {showthankyou && <div className="alert alert-success">
                                            Thank you for your interest. A member of our team will contact you shortly.
                                        </div>}
                                        {
                                            errorFlag &&
                                            <div className="alert alert-danger alert-error">Highlighted fields are required | invalid</div>
                                        }
                                        <div className='form-inputs'>
                                        <Row>
                                            <Col md={6}>
                                            <TextField
                                                label="First Name"
                                                name={`fname`}
                                                placeholder=""
                                                onChange={handleChange}
                                                value={formState[`fname`]}
                                                className={errClass(`fname`)}
                                            />
                                            </Col>
                                            <Col md={6}>
                                            <TextField
                                                label="Last Name"
                                                name={`lname`}
                                                placeholder=""
                                                onChange={handleChange}
                                                value={formState[`lname`]}
                                                className={errClass(`lname`)}
                                            />
                                            </Col>
                                            </Row>
                                            <Row>
                                            <Col md={6}>
                                            <TextField
                                                label="Email Address"
                                                name={`email`}
                                                placeholder=""
                                                onChange={handleChange}
                                                className={errClass(`email`)}
                                                value={formState[`email`]}
                                            />
                                            </Col>
                                            <Col md={6}>
                                            <TextField
                                                type="number"
                                                label="Telephone"
                                                name={`telephone`}
                                                placeholder=""
                                                onChange={handleChange}
                                                className={errClass(`telephone`)}
                                                value={formState[`telephone`]}
                                            />
                                            </Col>
                                            
                                            </Row>
                                           
                                            <TextArea
                                            label="Message"
                                            rows={5}
                                            name="message"
                                            placeholder="Enter your message"
                                            onChange={handleChange}
                                            className="textarea"
                                            value={formState[`message`]}
                                        />
                                        </div>
                                        
                                        <Recaptcha recaptchaRef={recaptchaRef} />
                                        <div className="form__footer">
                                <button type="submit"
                                    disabled={btnProcess}
                                    onClick={(event) => { handleSubmit(event) }}
                                    className='btn btn--yellow fz14 fw-bolder'
                                >Send Enquiry</button>
                                <div className='form-footer-text'>
                                            By clicking Submit, you agree to our <Link to="/terms-and-conditions/">Terms & Conditions</Link> and <Link to="/privacy-policy/">Privacy Policy</Link>.
                                        </div>
                                </div>
                                
                            </Col>
                        </Row>
                    
                </div>
                </div>
                </div>
            </section>
        </React.Fragment >
    )

}
export default SpeakMotrgageForm;