/**
 * Mortgage Calculator
 *
 * You can changes the default values in config
 *
 * DEV:NOTES
 * Please do not do nay changes in the DO NOT DO ANY CHNAGES block
 * Do import in use project specific import here
 * And play with the render layout structure and keep the params
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Row, Col, Button, Form } from 'react-bootstrap';
import { pmt, numericWithDecimal } from "./util"
import { currencyFormat, filterNumber, numberFormat } from "@components/common/utils";
import { defaultValues } from "./config"
import TextField from "@components/formComponents/textField";
// use project specific import here
import { calculateMonthlyPayment } from "./mortgage"



// User project specific const
const durationOptions = [10, 15, 20, 25, 30, 35]

// DO NOT DO ANY CHNAGES - START
const MortgageCalc = props => {
  const {
    propertyPrice,
    depositAmount,
    loadDuration,
    interestRate,
    currency,
    title,
    defaultResult,
    pricePrefix,
    // instantResult,
    // durationSuffix,
    // yearSuffix,
    // yearSelect,
  } = props

  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [calculator_value, setCalculatorValue] = useState({ 'loan_amount': ((75 / 100) * props.calc_price).toFixed(2), 'purchase_price': props.calc_price, 'interest_rate': '3', 'amortization': '30', 'down_payment': ((25 / 100) * props.calc_price) });


  const prefix = pricePrefix ? currency : ""
  const [validated, setValidated] = useState(false)
  const [showerror, setShowerror] = useState(false)
  const [purchasePrice, setPurchasePrice] = useState(numberFormat(propertyPrice))
  const [deposit, setDeposit] = useState(prefix + numberFormat(depositAmount))
  const [duration, setDuration] = useState(loadDuration)
  const [interest, setInterest] = useState(interestRate)
  const [loan, setLoan] = useState("")
  const [result, setResult] = useState("")

  const handlePrice = event => {
    let val = filterNumber(event.target.value)
    setPurchasePrice(numberFormat(val))
    if (parseInt(filterNumber(deposit)) && parseInt(val)) {
      let loan = parseInt(val) - parseInt(filterNumber(deposit))
      setLoan(prefix + numberFormat(loan))
    } else {
      setLoan(prefix + 0)
    }
  }

  

  const handleDuration = event => {
    setDuration(filterNumber(event.target.value))
  }

  const handleInterest = event => {
    setCalculatorValue({ ...calculator_value, [event.target.name]: event.target.value > 99 ? 99 : numericWithDecimal(event.target.value) })



    setInterest(event.target.value.replace(/[^\d.]/g, ""))
  }

  const handleLoan = event => {
    setLoan(prefix + numberFormat(filterNumber(event.target.value)))
  }

  const getResult = (interest, duration, loan) => {
    let result = -pmt(
      interest / 100 / 12,
      filterNumber(duration) * 12,
      filterNumber(loan),
      0,
      1
    )
    setResult(numberFormat(Math.round(result)))
  }

  const handleSubmit = event => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === false) {
      setShowerror(true)
      setValidated(true)
    } else {

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'Calculator',
        'formType': title,
        'formId': "Mortgage Calculator",
        'formName': "Mortgage Calculator",
        'formLabel': "Mortgage Calculator"
      });
      setShowerror(false)
      setValidated(false)
      if (
        parseInt(filterNumber(purchasePrice)) &&
        parseInt(filterNumber(deposit))
      ) {
        let loan =
          parseInt(filterNumber(purchasePrice)) -
          parseInt(filterNumber(deposit))
        setLoan(prefix + numberFormat(loan))
        getResult(interest, duration, loan)

        // console.log('duration',parseInt(filterNumber(purchasePrice)),interest,parseInt(filterNumber(deposit)),duration)

        setMonthlyPayment(
          calculateMonthlyPayment(
            parseInt(filterNumber(purchasePrice)),
            parseFloat(interest),
            parseInt(filterNumber(deposit)),
            duration
          )
        );

        // console.log("duration1",monthlyPayment)

      }
    }
  }

  useEffect(() => {
    if (defaultResult) {
      if (
        parseInt(filterNumber(purchasePrice))
      ) {
        let loan =
          parseInt(filterNumber(purchasePrice))
        setLoan(prefix + numberFormat(loan))
      }
      getResult(interest, duration, loan)
    }
  }, [defaultResult, purchasePrice, deposit, loan, interest, duration, prefix])

  // DO NOT DO ANY CHNAGES - END
  var monthly_payment = Math.round(monthlyPayment);

  return (
    <div className="mortgage-calculator-wrapper calculator-form">
      {showerror && (
        <div className="alert alert-danger alert-error">
          <p>* All fields are required</p>
        </div>
      )}
      <Form
        name="MortgageCalc"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <Row>
          <Col md="6" className="flex-shrink-1 ps-md-2  mb-5 col-md-6 col-12" >
            <Form.Group controlId="purchase_price">
              <div className="text-field-wrapper ">
                {/* <Form.Label>How much do you want to borrow?</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="purchase_price"
                  value={purchasePrice}
                  placeholder="Purchase Price"
                  onChange={handlePrice}
                /> */}
                <TextField
                  name="purchase_price"
                  type="text"
                  label="How much do you want to borrow?"
                  class="form-control"
                  inlineIcon="poundIcon"
                  inputClassName="outline-grey-border"
                  placeholder={'Purchase Price'}
                  value={purchasePrice}
                  onChange={handlePrice}
                />
              </div>
            </Form.Group>

          </Col>

          <Col md="6" className="flex-shrink-1 ps-md-2  mb-5 col-md-6 col-12" >
            <Form.Group controlId="interest_rate">
              <div className="text-field-wrapper">
                <Form.Label>Interest Rate (%)</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="interest_rate"
                  value={numericWithDecimal(calculator_value.interest_rate)}
                  placeholder="Interest Rate (%)"
                  onChange={handleInterest}
                  maxLength={99}
                />
              </div>
            </Form.Group>
          </Col>


          <Col md="6" className="flex-shrink-1 ps-md-2  mb-5 col-md-6 col-12" >
            <Form.Group controlId="duration">
              <div className="text-field-wrapper custom-float--select">
                <Form.Label>Over how many years?</Form.Label>
                <Form.Control
                  as="select"
                  className="form-control form-select"
                  required
                  name="duration"
                  value={duration}
                  placeholder="Duration (Years)"
                  onChange={handleDuration}
                >
                  {durationOptions.map((value, key) => {
                    return (
                      <option value={value} key={key}>
                        {value} Years
                      </option>
                    )
                  })}
                </Form.Control>
              </div>
            </Form.Group>
          </Col>
                </Row>
                <Row>
                <Col md="12" className="mb-24" >
            {monthly_payment != 0 && monthly_payment && (
              <div className="monthly-payment investment-monthly-payment">
                <p className="text-left mb-0 bold-para">Your Monthly Payments </p>
                <h3 className="text-left py-5">{currency} {numberFormat(monthly_payment)} <span>per month</span></h3>
              </div>
            )}
          </Col>
                </Row>
                <Row>
          <Col md="6" className="form-action flex-shrink-1 ps-md-2 col-md-6 col-12" >
            <Button
              variant="primary-light"
              type="submit"
              className="btn is-larger btn--yellow body-l"
            >
              Calculate
            </Button>
          </Col>
        </Row>
        <Row>
          
        </Row>
      </Form>

    </div>
  )
}

MortgageCalc.defaultProps = {
  propertyPrice: defaultValues.PROPERTY_VALUE,
  depositAmount: defaultValues.DEPOSIT,
  loadDuration: defaultValues.DURATION,
  interestRate: defaultValues.INTEREST,
  currency: process.env.CURRENCY
    ? process.env.CURRENCY
    : defaultValues.CURRENCY,
  defaultResult: defaultValues.DEFAULT_RESULT,
  pricePrefix: defaultValues.PRICE_PREFIX,
  // instantResult: defaultValues.INSTANT_RESULT,
  // durationSuffix: defaultValues.DURATION_SUFFIX,
  // yearSuffix: defaultValues.YEAR_SUFFIX,
  // yearSelect: defaultValues.YEAR_SELECT,
}

MortgageCalc.propTypes = {
  propertyPrice: PropTypes.any,
  depositAmount: PropTypes.any,
  loadDuration: PropTypes.any,
  interestRate: PropTypes.any,
  currency: PropTypes.string,
  defaultResult: PropTypes.any,
  pricePrefix: PropTypes.any,
  // instantResult: PropTypes.any,
  // durationSuffix: PropTypes.any,
  // yearSuffix: PropTypes.any,
  // yearSelect: PropTypes.any
}

export default MortgageCalc
