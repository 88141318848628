import React, { useState } from "react"
import { Button, Col, Row } from "react-bootstrap";
import { rentalYield } from "./util"
import TextField from "@components/formComponents/textField";
import { currencyFormat, filterNumber, numberFormat } from "@components/common/utils";


// DO NOT DO ANY CHNAGES - START
const RentalyieldCalc = props => {
  const { title } = props

  const currency = process.env.GATSBY_SITE_CURRENCY ? process.env.GATSBY_SITE_CURRENCY : "£";
  const [validated, setValidated] = useState(true)
  const [purchasePrice, setPurchasePrice] = useState(numberFormat(filterNumber(props.price)));
  const [rent, setRent] = useState(numberFormat(1000))
  const [result, setResult] = useState(null)

  const handlePrice = event => {
    let val = (event.target.value)
  
    setPurchasePrice(numberFormat(filterNumber(val)))
  }

  const handleRent = event => {
    setRent(numberFormat(filterNumber(event.target.value)))
  }

  const getResult = (purchasePrice, rent) => {
    let result = rentalYield(filterNumber(purchasePrice), filterNumber(rent))
    setResult(numberFormat(result))
  }

  const handleSubmit = event => {
    event.preventDefault()
    event.stopPropagation()
    if (filterNumber(purchasePrice) === 0 || filterNumber(rent) === 0) {
      setValidated(false)
    }
    else {
      setValidated(true)
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'Calculator',
        'formType': title,
        'formId': "Rental Yield Calculator",
        'formName': "Rental Yield Calculator",
        'formLabel': "Rental Yield Calculator"
      });
      if (filterNumber(purchasePrice) && filterNumber(rent)) {
        getResult(purchasePrice, rent)
      }
    }
  }

  return (
    <div className="buy-to-let-wrapper calculator-form">

      {!validated && (
        <div className="alert alert-danger alert-error"><p>* All fields are required</p></div>
      )}
      <Row>
        <Col md="6" className="flex-shrink-1 ps-md-2  mb-5 col-md-6 col-12" >
          <TextField
            name="purchase_price"
            type="text"
            label="Property Price (£):"
            inlineIcon="poundIcon"
            class="form-control"
            inputClassName="outline-grey-border"
            placeholder={'Purchase Price'}
            value={purchasePrice}
            onChange={(e) => {
              setResult(null)
              handlePrice(e)
            }}
          />
        </Col>
        <Col md="6" className="flex-shrink-1 ps-md-2  mb-5 col-md-6 col-12" >
          <TextField
            name="monthly_rent"
            type="text"
            label="Monthly Rent (£):"  
            class="form-control"
            inlineIcon="poundIcon"
            inputClassName="outline-grey-border"
            placeholder={'Monthly Rent'}
            value={rent}
            onChange={(e) => {
              setResult(null)
              handleRent(e)
            }}
          />
        </Col>
      </Row>
      <Row>
      <Col md="6" className="mb-24" >
          {result && (
            <div className="monthly-payment investment-monthly-payment">
              <p className="text-left mb-0 bold-para"> Estimated rental yield </p>
              <h3 className="text-left py-5">{result}%</h3>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col md="6" className="form-action flex-shrink-1 ps-md-2 col-md-6 col-12" >
          <Button
            variant="primary-light"
            type="submit" 
            onClick={(event) => { handleSubmit(event) }}
            className="btn is-larger btn--yellow body-l"
          >
            Calculate Yield
          </Button>
        </Col>
        
      </Row> 
    </div>

  )
}

export default RentalyieldCalc